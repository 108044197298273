import React from 'react'
import Layout from '../components/Layout'
import Grid from '@mui/material/Grid';
import BreadCrumb from '../components/BreadCrumb'
import { IconButton,Typography } from '@mui/material';
import { graphql, Link} from 'gatsby';
import { styled } from '@mui/material/styles';
import Hidden from '@mui/material/Hidden';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Slugger from "github-slugger";
import {Helmet} from 'react-helmet'
import { MDXRenderer } from "gatsby-plugin-mdx";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import ReplySharpIcon from '@mui/icons-material/ReplySharp';
import { useBookmark } from '../context/BookmarkContext';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { useSnackbar } from 'notistack';
import AvatarGroup from '@mui/material/AvatarGroup';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { useMsGraph } from '../context/MsGraphContext';
import CircularProgress from '@mui/material/CircularProgress';
import Sidebar from '../components/Sidebar';
import { List, ListItem, ListItemText, ListItemButton } from '@mui/material/';
import MdxNavigation from '../components/MdxNavigation';

// create the slugger instance
const slugger = new Slugger();

const StyledSideBar = styled(Sidebar)(({theme}) => ({
  // padding: "0 20px",
  // [theme.breakpoints.only('md')]: {
  //   maxWidth: "580px",
  // },
  // maxHeight: "calc(100vh - 64px)",
  // position: "sticky",
  // overflow: "auto",
}))



const Article = styled(Grid)<{component: React.ElementType<any>}>(({theme}) => ({
  // maxWidth: 1200,
  // marginRight: "70px",
  // marginLeft: "70px",
  // padding: "40px 70px 20px 70px",
  minHeight: '1000px',
  padding: "40px 70px 20px 70px",
  [theme.breakpoints.down("sm")]: {
    padding: "20px"
  },
}))

const StyledAvatar = styled(Avatar)(() => ({
  marginLeft: 4,
}))


// Get the post and parent in graphql query
type FileSubset = Extract<
GatsbyTypes.GetInformationQuery["post"]["parent"],
{ __typename: "File" }
>;

// get all the git commits
type GitCommitSubset = Extract<
  FileSubset["gitChanges"][0]["gitCommit"],
  { __typename: "GitCommit" }
>;

export interface BookmarkItems {
pagePath: string,
pageTitle: string,
pageHref: string,
lastEdit: string,
image: string,
}

// get the props data, pageContext, and location
export default function Pages({props,data,pageContext,location} : {props: any, data: any, pageContext: any, location: Location}) {
    
    const {post} = data
    const { graphData } = useMsGraph();
    const [loading, setLoading] = React.useState<boolean>(true)
    const country = graphData !== null ? graphData?.country : null
    const allowAccess = post.childNavigation.allowAccess?.includes(country) !== undefined ? post.childNavigation.allowAccess?.includes(country) : null;

    React.useEffect(() => {
      if (graphData !== null) {
        setLoading(false)
      }
    },[graphData])

    const PageContent = () => {
      return (
        <>
        { allowAccess || allowAccess === null ? 
        <>
        <MDXRenderer>
          {post.body}
        </MDXRenderer>
        {/* <Grid container justifyContent="center" flexDirection="column" alignItems='center'>
            <Grid item>
              <Typography
              sx={{color: (theme) => theme.palette.darkColors.black80, fontSize: '20px', fontWeight: 700}}
              >
                Was this content helpful?
              </Typography>
            </Grid>
            <Grid item>
              <IconButton>
                <ThumbUpOffAltIcon />
              </IconButton>
              <IconButton>
                <ThumbDownOffAltIcon />
              </IconButton>
            </Grid>
          </Grid> */}
        </>

        : 
        <Grid container>
          <Grid item sx={{paddingTop: "35px"}}>
            <Typography variant="h3" gutterBottom>Forbidden</Typography>
            <Typography fontSize="18px" variant="body2">You don't have permission to access this resource. Contact the team related to this documentation and find out if you have access permission.</Typography>
          </Grid>
        </Grid>
        }
        </>
      )
    }


    // gets the title, slug and navigationChildren from the graphql Navigation
    const navigation = data.navigation as Pick<
    GatsbyTypes.Navigation,
    "title" | "slug" | "navigationChildren"
    >;
    
    // get the parent as FileSubset
    const parent: any = post.parent as FileSubset;

    // get all the editors of the files
    const editors = React.useMemo(() => {
      let changes: any = parent.gitChanges;
      let counts: any =
        changes &&
        changes.reduce(
          (
            counter: {
              [key: string]: Pick<
                GatsbyTypes.GitAuthor,
                "name" | "email" | "imageUrl"
              > & { changes: number };
            },
            {
              gitCommit,
            }: {
              gitCommit: any;
              
            }
          ) => {
            if (counter[gitCommit.author.email])
              counter[gitCommit.author.email].changes++;
            else
              counter[gitCommit.author.email] = {
                changes: 1,
                ...gitCommit.author,
              };
              return counter;
          },
          {}
        );
      return (
        counts && Object.values(counts).sort((a: any, b: any) => a.changes - b.changes)
      );
    }, [post.parent]);

    // get the latest Commits in each files
    const latestCommit:any = React.useMemo(() => {
      let changes = parent.gitChanges
      //check if gitCommit is undefined or not 
      if (typeof changes[0] != 'undefined') {
       return changes && (changes[0].gitCommit as GitCommitSubset) 
      }
    }, [post.parent])

    slugger.reset();

    // navigation page go to the previous page
    function getPreviousItem(page: any) {
      if (page.navigationParent) {
        const currentIndex =
          page.navigationParent.navigationChildren &&
          page.navigationParent.navigationChildren
            .map((nav: any) => {
              return nav.slug;
            })
            .indexOf(pageContext.slug);
        return currentIndex === 0
          ? null
          : page.navigationParent.navigationChildren[currentIndex - 1];
      }
      return null;
    }

    // go to the next page
    function getNextItem(page: any, slug: any, isRecurse?: boolean) {
      if (page.navigationParent && page.navigationParent.navigationChildren) {
        const currentIndex = page.navigationParent.navigationChildren
          .map((nav: any) => {
            return nav.slug;
          })
          .indexOf(slug);
        return currentIndex ===
          page.navigationParent.navigationChildren.length - 1
          ? null
          : page.navigationParent.navigationChildren[currentIndex + 1];
      }
      return null;
    }

    // go to the previous page using useMemo
    const previousItem = React.useMemo(() => {
      return getPreviousItem(post.childNavigation);
    }, [post.childNavigation]);

    // go to the next page using useMemo
    const nextPage = React.useMemo(() => {
      return getNextItem(post.childNavigation, pageContext.slug);
    }, [post.childNavigation]);

    // get the breadcrumb location
    const breadcrumbsArray = React.useMemo(() => {
        let crumbArray = [post.childNavigation] as Array<
          Pick<GatsbyTypes.Navigation, "slug" | "title" | "navigationParent">
        >;
        while (crumbArray[crumbArray.length - 1].navigationParent)
          crumbArray.push(crumbArray[crumbArray.length - 1].navigationParent!);
        return crumbArray.slice(1).reverse();
      }, [post.childNavigation]);

    
  const { addBookmarkData, bookmarkValue} = useBookmark()
  const bookmarkExists = bookmarkValue.some((item) => item.pageTitle === post.frontmatter.front_matter_title)

  const handleAddBookmark = () => {
    addBookmarkData(post.frontmatter.front_matter_title, latestCommit.date)
  }

  const {enqueueSnackbar} = useSnackbar()

  function copyURL()
  {
    var copyText = `https://${location.hostname}${location.pathname}`;
    navigator.clipboard.writeText(copyText);
    enqueueSnackbar(`The URL ${copyText} has been copied.`,{variant: 'success', anchorOrigin: {vertical: 'bottom', horizontal: 'right'}})
  }

  const handleCopyURL = () => {
    copyURL()
  }

    return (
        <Layout 
          navigation={navigation} 
          location={location}
        >
          <Helmet title={`SEM Wiki - ${post.frontmatter.front_matter_title}`} defer={false}/>    
            <Grid
            container
            display="block"
            flexDirection="row"
            justifyContent="space-between"
            sx={{ 
            paddingTop: "50px", 
            width: '100%',
            marginBottom: {
              xl: loading || allowAccess === false ? "400px" : 0,
              lg: loading || allowAccess === false ? "100px" : 0,
              md: loading || allowAccess === false ? "75px" : 0,
              sm: loading || allowAccess === false ? "50px" : 0,
              xs: 0,
            }
          }}
            >   
 

              {/* Left Sidebar Article */}
              {/* <Hidden mdDown>
                <Grid item container component="nav" sx={{ maxWidth: "580px" }} sm={2}>
                    <Sidebar side="left" sx={{
                    padding: {
                      lg: "0 20px",
                    }, 
                    // maxWidth: {
                    //   md: "580px"
                    // },
                    maxHeight: "calc(100vh - 64px)",
                    position: "sticky",
                    overflow: "auto",
                    }}>
                      <List>
                        <ListItem component='a' href={navigation.slug}>
                          <Typography color='#205527' fontWeight={700}>{navigation.title}</Typography>
                        </ListItem>
                        {navigation.navigationChildren.map(
                          (nav) => 
                          !nav.hidden && (
                            <MdxNavigation 
                            navigation={nav}
                            key={nav.slug}
                            location={location}
                            />

                          )
                        )}
                      </List>
                    </Sidebar>
                </Grid>
              </Hidden> */}


                <Article item component="article">
                      <BreadCrumb title={post.frontmatter.front_matter_title} breadcrumbsArray={breadcrumbsArray}/>
                      <Grid container sx={{justifyContent: 'flex-end'}}>
                        <Grid item>
                          <IconButton onClick={() => handleAddBookmark()}>
                            {
                              bookmarkExists ? <BookmarkIcon fontSize='medium' sx={{color: (theme) => theme.palette.primary.main}}/> : <BookmarkBorderIcon fontSize='medium' sx={{color: '#666666'}}/>
                            }
                          </IconButton>
                          <IconButton onClick={() => handleCopyURL()}>
                            <ReplySharpIcon fontSize='medium' sx={{color: '#666666', transform: 'scaleX(-1)'}}  />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Typography sx={{fontSize: "32px", lineHeight: "36.48px", color: '#333333',fontWeight: '700'}} gutterBottom>
                          {post.frontmatter.front_matter_title}
                      </Typography>
                      <Breadcrumbs separator="|" aria-label="breadcrumb">
                          <Grid container>
                            <AvatarGroup total={10}>
                              {editors &&
                                editors.map((editor: any) => (
                                    <StyledAvatar
                                      title={editor.email}
                                      key={editor.email}
                                      src={editor.imageUrl}
                                    
                                    />
                                ))}
                              </AvatarGroup>
                          </Grid>
                          <Typography 
                          sx={{
                            fontSize: "14px",
                            lineHeight: "16px",
                            color: (theme:any) => (theme.palette.grey[800])
                          }}
                          >
                              {post.timeToRead} min to read
                          </Typography>
                          {latestCommit && (
                            <Typography
                            sx={{
                              fontSize: "14px",
                              lineHeight: "16px",
                              color: (theme:any) => (theme.palette.grey[800])
                            }}
                            >
                              Last edited on {latestCommit.date} by {latestCommit.author.name}
                            </Typography>
                          )}{" "}
                          {latestCommit && (
                            <div>
                            <Button size='small'
                            rel="noreferrer noopener"
                            target="_blank"
                            href={`${latestCommit.gitRepository.url}/?path=${parent.relativePath}`}
                            sx={{ display: 'flex', minWidth: '30px'}}
                            >
                                <Typography
                                sx={{color: (theme:any) => (theme.palette.primary.main), fontStyle:'normal', textTransform:'capitalize'}}                                 
                                >
                                    Edit
                                </Typography>
                            </Button>
                            </div>
                          )}
                      </Breadcrumbs >



                    {/* The body of the page */}
                        {/* <div className= dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(post.html)}} /> */}
                        {/* <div className={classes.content} id="content">
                          
                        </div> */}

                  <div id="content" style={{scrollMarginTop: "70px"}}>
                      {/* <MDXRenderer>
                          {post.body}
                      </MDXRenderer> */}
                      {loading ? 
                      <Grid container justifyContent="center" sx={{paddingTop: "40px", paddingBotton: "40px"}}>
                        <Grid item>
                          <CircularProgress />
                        </Grid>
                      </Grid>
                      : 
                      PageContent()}
                  </div>

                    <Hidden mdUp>
                    {previousItem && (
                      <Button
                        component="a"
                        href={previousItem.slug}
                        rel="  noopener"
                      >
                        {/* {`< ${previousItem.title}`} */}
                         {`< Previous`}
                      </Button>
                    )}

                    {nextPage && (
                      <Button
                        component="a"
                        href={nextPage.slug}
                        rel="noreferrer noopener"
                        style={{ float: "right" }}
                      >
                        {`Next >`}
                      </Button>
                    )}
                    </Hidden>

                </Article>
                {/* In this article */}
                {/* <Hidden smDown>
                    <Grid item sm={2} component="aside">
                        <Sidebar side="right">
                            <List>
                                <ListItem>
                                    <Typography variant="h6">
                                            In this article
                                    </Typography>
                                </ListItem>
                                {post.headings.map((heading: any) => {
                                  const fragment = `#${slugger.slug(heading.value)}`;
                                  return heading.depth == 1 ? (
                                    <ListItem component="a" href={fragment} dense key={heading.value} sx={{textDecoration: 'none'}}> 
                                      <ListItemButton sx={{padding: "4px"}}>
                                        <Typography color='#333333' fontWeight={700}>{heading.value}</Typography>
                                      </ListItemButton>
                                    </ListItem>
                                  ) : (
                                    heading.depth == 2 && (
                                      <ListItem component='a' href={fragment} dense key={heading.value}>
                                         <ListItemButton sx={{padding: "4px"}}>
                                          <ListItemText secondary={heading.value}/>
                                         </ListItemButton>
                                      </ListItem>
                                    )
                                  )
                                })}
                            </List>
                        </Sidebar>
                    </Grid>
                </Hidden> */}
  
            </Grid>
        </Layout>
    )
}

// GetInformation query from the graphql
export const query = graphql`
query GetInformation($slug: String!, $sourceInstanceName: String!) {
  site {
    siteMetadata {
      sourceUrl
    }
  }
  file(relativePath: {eq: "banner.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: BLURRED, quality: 90)
    }
  }
  post: mdx(fields: {slug: {eq: $slug}}) {
    body
    excerpt
    timeToRead
    fields {
      slug
    }
    parent: parent {
      ... on File {
        birthTime
        id
        name
        relativePath
        gitChanges {
          gitCommit: parent {
            ... on GitCommit {
              author {
                email
                imageUrl
                name
              }
              date(formatString: "M/D/YYYY")
              gitRepository: parent {
                ... on GitRepository {
                  url
                }
              }
            }
          }
        }
      }
    }
    frontmatter {
      front_matter_title
    }
    headings {
      depth
      value
    }
    childNavigation {
      allowAccess
      slug
      title
      navigationParent {
        slug
        title
        navigationChildren {
          slug
          title
          navigationParent {
            slug
            title
            navigationParent {
              slug
              title
              navigationParent {
                slug
                title
                navigationParent {
                  slug
                  title
                }
              }
            }
          }
        }
      }
    }
  }
  navigation(
    filePath: {eq: "index.md"}
    sourceInstanceName: {eq: $sourceInstanceName}
  ) {
    hidden
    slug
    title
    navigationChildren {
      hidden
      slug
      title
      navigationChildren {
        hidden
        slug
        title
        navigationChildren {
          hidden
          slug
          title
          navigationChildren {
            hidden
            slug
            title
          }
        }
      }
    }
  }
}
`