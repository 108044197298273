import React from "react";
import stickybits from "stickybits";
import cx from "classnames";
import  {Grid, GridProps} from '@mui/material/';
import {makeStyles,createStyles} from '@mui/styles';
import CircleIcon from '@mui/icons-material/Circle';

// create custom design using useStyles naming convention
const useStyles = makeStyles(
  (theme:any) =>
    createStyles({
      root: {
        maxHeight: "calc(100vh - 64px)",
        height: "auto",
        border: "solid rgba(0, 0, 0, 0.12) 0px",
        zIndex: 500,
        overflowY: "auto",
        "-ms-overflow-style": "none", 
        scrollbarWidth: "none", 
        "&::-webkit-scrollbar": {
          display: "none", 
        },
      },
      fullHeight: {
        height: "100%",
        maxHeight: "100%",
      },
      right: {
        borderWidth: "0 0 0 1px",
      },
      left: {
        borderWidth: "0 1px 0 0",
      },
    }),
  { name: "Sidebar" }
);


// SidebarProps extends the GridProps component
export interface SidebarProps extends GridProps {
  fullHeight?: boolean;
  side?: "right" | "left";
  offset?: number;
  className?: any;
  children: any;
}

export default function Sidebar(props: SidebarProps) {

  const sidebarRef = React.useRef<HTMLDivElement>(null);
  const styles = useStyles(props);
  const [scrollPosition, setScrollPosition] = React.useState(0)
  const key = "@@sidebar" 
  const oldScrollValue: number = JSON.parse(sessionStorage.getItem(key))

  // get the current scroll position of the sidebar then store it in the session storage
  const updateScrollPosition = () => {
    const position = sidebarRef.current.scrollTop
    setScrollPosition(position)
    sessionStorage.setItem(key, JSON.stringify(position))
  }
  // retrieve the old scroll value in the session storage
  React.useEffect(() => {
    sidebarRef.current.scrollTop = oldScrollValue
  },[])

  // extend the sidebar depending on the size of the screen
  React.useEffect(() => {
    if (!props.fullHeight && sidebarRef.current != null)
      stickybits(sidebarRef.current, {
        stickyBitStickyOffset: props.offset || 64,
      });
  }, [sidebarRef]);

  // listen to the left sidebar 
  React.useEffect(() => {
    if (typeof(sidebarRef.current) != null) {
      if (props.side == "left") {
        sidebarRef.current.addEventListener('scroll', updateScrollPosition, {passive: true})
      }
    }
  },[])

  return (
    <Grid
      container
      direction="column"
      ref={sidebarRef}
      className={cx(
        styles.root,
        props.fullHeight && styles.fullHeight,
        props.side == "right" && styles.right,
        props.side == "left" && styles.left,
        props.className
      )}
      {...props}
    >
      {props.children}
    </Grid>
  );
}
